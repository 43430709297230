import { Control } from "ol/control";

const setVisible = (elem, prop) => {
  if (!prop) {
    elem.classList.add("hidden");
    return false;
  } else {
    elem.classList.remove("hidden");
    return true;
  }
};

export default class InfoControl extends Control {
  constructor(opt_options) {
    const options = opt_options || {};
    const box = document.createElement("div");
    const header = document.createElement("div");
    header.id = "info-control-header";

    const headerTitle = document.createElement("div");
    headerTitle.id = "header-title";
    headerTitle.innerHTML = "Info";

    const backButton = document.createElement("div");
    backButton.id = "info-control-back";
    backButton.innerHTML = "←";
    backButton.style.display = "none";

    header.appendChild(backButton);
    header.appendChild(headerTitle);
    box.appendChild(header);

    const body = document.createElement("div");
    body.id = "info-control-body";
    body.innerHTML = ``;
    let bodyVisible = setVisible(body, true);
    box.appendChild(body);
    box.className = "info-control ol-unselectable ol-control";

    header.onclick = () => {
      if (bodyVisible) {
        bodyVisible = setVisible(body, false);
      } else {
        bodyVisible = setVisible(body, true);
      }
    };
    super({ element: box, target: options.target });
    this.box = box;
    this.header = header;
    this.title = headerTitle;
    this.backButton = backButton;
    this.body = body;
  }
  setHeader(header) {
    this.title.innerHTML = header;
  }
  setBody(body) {
    this.body.innerHTML = body;
  }
  showBack() {
    this.backButton.style.display = "flex";
  }
  hideBack() {
    this.backButton.style.display = "none";
  }
  setContent(title, body) {
    this.setHeader(title);
    this.setBody(body);
  }
}
