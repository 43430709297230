import { DISTRICT_LAYER, LSGsByDistrict, LSG_LAYER } from "./data";

export const getLSGIsOfDistrict = (district) =>
  LSGsByDistrict[district].sort((a, b) => a.name.localeCompare(b.name));

export const getLSGHtml = (lsgs) =>
  lsgs
    .map(
      (l) =>
        `<a href="#" onclick="navigateTo('lsg', '${l.wikidata}');return false;">
          <div>${l.name}</div>
        </a>`
    )
    .join("");

export const getLSGHTMLforDistrict = (district) =>
  getLSGHtml(getLSGIsOfDistrict(district));

export const fitViewToFeature = (mapView, feature) =>
  mapView.fit(feature.getGeometry(), { duration: 1000 });

const buildFeatureMap = () => {
  const districtMap = {};
  DISTRICT_LAYER.getSource()
    .getFeatures()
    .forEach((f) => {
      const name = f.get("name");
      if (name in districtMap) {
        throw `${name} already exists in districts`;
      }
      districtMap[name] = f;
    });
  const lsgMap = {};
  LSG_LAYER.getSource()
    .getFeatures()
    .forEach((f) => {
      const qid = f.get("wikidata");
      if (qid in districtMap) {
        throw `${qid} already exists in lsgs`;
      }
      lsgMap[qid] = f;
    });
  return {
    district: districtMap,
    lsg: lsgMap,
  };
};

const mappedToFeatures = buildFeatureMap();

export const getFeatureCached = (level, id) => mappedToFeatures[level][id];

export const initialInfoBoxBody = DISTRICT_LAYER.getSource()
  .getFeatures()
  .map((f) => f.get("name"))
  .filter((i) => i != null)
  .sort()
  .map(
    (d) =>
      `<a href="#" onclick="navigateTo('district', '${d}');return false;">
        <div>${d}</div>
      </a>`
  )
  .join("");

export const convertObjectToTable = (obj) =>
  "<table>" +
  Object.keys(obj)
    .map((k) => `<tr><td>${k}</td><td>${obj[k]}</td></tr>`)
    .join("") +
  "</table>";
