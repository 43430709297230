Franchisee Application No,NAME,Status,Latitude,Longitude,District,LSG Type,LSG Name,Ward number,Post office,PIN Code
FF-FRE-000001,Abilash,Confirmed Franchise,10.0190617,76.3179325,Ernakulam,Muncipapality,Thrikakkara,,,
FF-FRE-000003,Fathima Labeeb,Confirmed Franchise,10.26756213,76.21608004,Thrissur,Panchayat,Vellangallur ,"8,9,10,11",KARUMATHRA,680123
FF-FRE-000004,GOKUL BABU,Confirmed Franchise,10.3468413,76.2071619,Thrissur,Muncipapality,IRINJALAKUDA,,,
FF-FRE-000005,Harees,Confirmed Franchise,10.28153927,76.20592413,Thrissur,Panchayat,Vellangallur ,,,
FF-FRE-000011,Mohammed Naseemi,Confirmed Franchise,8.6246163,76.8855727,Thiruvananthapuram,Panchayat,Andoorkonam,15,Pallipuram,695316
FF-FRE-000016,Sefoor,Confirmed Franchise,10.59693711,76.0131273,Thrissur,Muncipapality,Chavakad,,,
FF-FRE-000017,Sreekanth R G,Confirmed Franchise,10.2465045,76.17271153,Thrissur,Panchayat,Edavilangu,"6,7,8",,680671
FF-FRE-000018,Sudheer. T. M.,Confirmed Franchise,10.06888555,76.39248207,Ernakulam,Panchayat,,,,683561
FF-FRE-000020,TELXON C R,Confirmed Franchise,10.03182151,76.28182478,Ernakulam,Panchayat,CHERANALLUR,"7,8,9,10,11,12",SOUTH CHITOOR,682027
FF-FRE-000021,Aliyar Kottapurath,Confirmed Franchise,10.10368528,76.42398036,Ernakulam,Panchayat,Vazhakulam,13,മാറമ്പള്ളി,683105
FF-FRE-000022,BAIJU KV,Confirmed Franchise,10.00091249,76.28021914,Ernakulam,Corporation,Kochi,73,,682012
FF-FRE-000096,A.K.vijayaragjavan,Confirmed Franchise,10.61372153,76.03502633,Thrissur,Muncipapality,Guruvayur,36,Kottapadi,680505
FF-FRE-000104,C B Valsalan ,Confirmed Franchise,10.65040966,76.06661473,Thrissur,Muncipapality,Guruvayur ,26,Thaikkad,680104
FF-FRE-000111,Jomy George Thommana ,Confirmed Franchise,10.3296751,76.227312,Thrissur,Panchayat,Muriyad,11,Pullur ,680683
FF-FRE-000115,Mohammed Nasik Kurikkal,Confirmed Franchise,11.12129078,76.107825,Malappuram,Muncipapality,Manjeri,8,Manjeri,676121
FF-FRE-000119,NAVAB V,Confirmed Franchise,10.72623579,76.42525341,Thrissur,Panchayat,Thiruvilwamala,12,Kaniyarkode,680594
FF-FRE-000122,Ramesan Nampoothiry ,Confirmed Franchise,9.675101198,76.5676292,Kottayam,Muncipapality,Ettumanoor ,4,Ettumanoor ,686631
FF-FRE-000124,Rasil Muhammed,Confirmed Franchise,10.0019301,76.6308722,Ernakulam,Panchayat,AYAVANA,03,ENANALLOOR,686673
FF-FRE-000128,Shameer  C Basheer ,Confirmed Franchise,9.903434772,76.73380379,Idukki,Muncipapality,Thodupuzha ,15,Thodupuzha East  P O,685585
FF-FRE-000131,Suresh. K. P,Confirmed Franchise,11.6634302,75.7826661,Kozhikode,Panchayat,Moilothara,13,Moilothara,673513
FF-FRE-000236,Abhilash kj,Confirmed Franchise,9.678140392,76.56197989,Kottayam,Muncipapality,Ettumanoor,3,Ettumanoor,686631
FF-FRE-000238,ABOOBACKER VALIYAKKA THODI,Confirmed Franchise,11.06398325,75.9557176,Malappuram,Panchayat,Vengara,6,Kannattipadi,676304
FF-FRE-000249,Anandanunni.s (Das),Confirmed Franchise,10.65294326,76.52555075,Palakkad,Panchayat,Kavassery panchayath,8,Kavassery,678543
FF-FRE-000251,Aneesh T N,Confirmed Franchise,10.0144421,76.3708883,Ernakulam,Muncipapality,Thrikkakara ,9,Thengode ,682030
FF-FRE-000258,ARUNLAL CS,Confirmed Franchise,10.56803949,76.25363347,Thrissur,Panchayat,MADAKATHRA GRAMA PANCHAYATH,16,KURICHIKARA ,680028
FF-FRE-000260,Ashish kamalan,Confirmed Franchise,8.903021115,76.60163608,Kollam,Corporation,Kollam,13,Asramam,691002
FF-FRE-000265,Beena Nair,Confirmed Franchise,9.380990851,76.57592841,Pathanamthitta,Muncipapality,Thiruvalla muncipality,14,Thiruvalla,689101
FF-FRE-000270,Biju Vijayan pillai,Confirmed Franchise,8.992750102,76.57587104,Kollam,Panchayat,Thevalakkara,17,Koivila,691590
FF-FRE-000274,Deepa ks,Confirmed Franchise,10.56366717,76.17313563,Thrissur,Panchayat,Adatt panchayat,6,Amalanagar,680555
FF-FRE-000305,Jaison Jos,Confirmed Franchise,10.45890493,76.2400203,Thrissur,Corporation,Thrissur Koorkenchery panchayath,23,Kuriachira,680006
FF-FRE-000314,JOHN THARIAN ELENJICAL,Confirmed Franchise,10.0364977,76.64805128,Ernakulam,Panchayat,Varapetty,2,Pallarimangalam,686671
FF-FRE-000317,Joshy Lawrence ,Confirmed Franchise,10.4764211,76.24390919,Thrissur,Panchayat,Puthur ,21,Ollur,680306
FF-FRE-000319,Jubin Babu M,Confirmed Franchise,11.2735477,75.8301724,Kozhikode,Corporation,Kozhikode,20,Chevayur,673017
FF-FRE-000321,K B MATHEWS ,Confirmed Franchise,9.34764628,76.53494757,Pathanamthitta,Panchayat,Kadapra ,15,Valanjavattom,689104
FF-FRE-000332,Linshu Sujith,Confirmed Franchise,11.25951858,75.78372999,Kozhikode,Corporation,Kozhikode,7,Karuvissery,673010
FF-FRE-000336,Mahesh.C,Confirmed Franchise,10.0247273,76.3037992,Ernakulam,Corporation,Cochin,37,Edapally,682024
FF-FRE-000340,Mathews K Chacko,Confirmed Franchise,9.207253904,76.74685471,Pathanamthitta,Panchayat,Pandalam Thekkekkara,6,Thattayil PO,691525
FF-FRE-000342,Miraj kt,Confirmed Franchise,11.98227377,75.55879449,Kannur,Panchayat,Irikkur,7,Irikkur,670593
FF-FRE-000347,Mohammed Yousef,Confirmed Franchise,11.08583215,76.25785616,Malappuram,Panchayat,Pandikkad,13,Kolaparamba,676523
FF-FRE-000358,Nesiya. M,Confirmed Franchise,10.76280982,75.95550221,Malappuram,Panchayat,Maranchery panchayat,19,Kanjiramukku,679584
FF-FRE-000364,Noufal k m,Confirmed Franchise,10.70431815,76.32253192,Thrissur,Panchayat,Panjal,10,Killimangalam ,680591
FF-FRE-000365,Noufal Odayapurath,Confirmed Franchise,10.95951843,75.98747704,Malappuram,Panchayat,Kalpakamchery,15,Randathani,676510
FF-FRE-000369,Pradeep Kumar.M,Confirmed Franchise,8.620219908,76.91357413,Thiruvananthapuram,Panchayat,Vembayam,2,Pothencode,695584
FF-FRE-000392,RICHU N M,Confirmed Franchise,9.516969197,76.3276046,Alappuzha,Muncipapality,ALAPPUZHA,2,THUMPOLY,688008
FF-FRE-000394,SAHEER BABU P P,Confirmed Franchise,11.25915147,75.77586562,Kozhikode,Corporation,Kozhikode,6,	Calicut Beach SO-Calicut-Beach,673032
FF-FRE-000395,SAIJU PS,Confirmed Franchise,9.894941344,76.36992008,Ernakulam,Panchayat,Udayamperoor,16,Nadakkavu,682307
FF-FRE-000401,Sam Kunnath,Confirmed Franchise,10.07183917,76.40126698,Ernakulam,Panchayat,Keerampara,6,Thattekkad PO,686681
FF-FRE-000405,SANJEEV PARAYIL ( SHOP -FRANCHISEE),Confirmed Franchise,10.41634919,76.22843331,Thrissur,Panchayat,Cherpu panchayat,second ward,Pootharakkal,680561
FF-FRE-000411,Savitha V P,Confirmed Franchise,10.1325869,76.356168,Ernakulam,Panchayat,Chengamanad,14,Desom PO,683102
FF-FRE-000415,Shajikumar,Confirmed Franchise,10.33868803,76.35705947,Thrissur,Panchayat,Kodasserry,20,Kodasserry,680721
FF-FRE-000419,Sharafudheen Illikkal,Confirmed Franchise,11.21570302,76.14051966,Malappuram,Panchayat,Edavanna,10,Edavanna,676541
FF-FRE-000422,Shibu D,Confirmed Franchise,9.141212512,76.85460514,Pathanamthitta,Panchayat,Kalanjur,19,Koodal,689693
FF-FRE-000424,Shihabudheen,Confirmed Franchise,11.0690726,76.34188176,Palakkad,Panchayat,Alanallur,3,Vattamannapuram,678601
FF-FRE-000425,Shiju,Confirmed Franchise,10.10367816,76.46911074,Ernakulam,Panchayat,Vengola,7,Allapara,683556
FF-FRE-000444,Sreekanth MV,Confirmed Franchise,9.5508336,76.7854163,Kottayam,Panchayat,Kanjirappally,21,Kanjirappally,686507
FF-FRE-000445,Sreekumar s,Confirmed Franchise,8.672192112,76.7673894,Thiruvananthapuram,Panchayat,Kadakkavoor,7,Kadakkavoor,695306
FF-FRE-000453,Sumasankar,Confirmed Franchise,8.948497492,76.60979485,Kollam,Panchayat,Thrikkaruva,2,Ashtamudy,691602
FF-FRE-000465,Thahir KK 1,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,12,Puthoor,676503
FF-FRE-000466,Thahir KK 10,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000467,Thahir KK 11,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000468,Thahir KK 12,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000469,Thahir KK 2,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000470,Thahir KK 3,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000471,Thahir KK 4,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000472,Thahir KK 5,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000473,Thahir KK 6,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000474,Thahir KK 7,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000475,Thahir KK 8,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000476,Thahir KK 9,Confirmed Franchise,11.00297876,76.00480554,Malappuram,Muncipapality,KOTTAKAL,,,
FF-FRE-000479,Tison Santu,Confirmed Franchise,10.06257166,76.61450483,Ernakulam,Muncipapality,Kothamangalam,30,Kothamangalam,686691
FF-FRE-000947,Adheesh Kumar B,Confirmed Franchise,9.64689698,76.53206117,Kottayam,Panchayat,Athirampuzha,15,P D hills,686560
FF-FRE-000952,AMBILI A S,Confirmed Franchise,9.616604759,76.88631351,Kottayam,Panchayat,KOOTTICKAL,7,MUKKULAM EAST,686514
FF-FRE-000953,Amrutharaj M T,Confirmed Franchise,8.7290822,76.7103413,Thiruvananthapuram,Muncipapality,വർക്കല,22,വർക്കല,695141
FF-FRE-000956,Aneesh v,Confirmed Franchise,10.71087645,76.08967414,Thrissur,Panchayat,Kadavallur,7,Korattikkara,680543
FF-FRE-000957,Ani M Abraham,Confirmed Franchise,9.286113,76.8355911,Pathanamthitta,Panchayat,Malayalapuzha,6,"Thazham -P. O.,",689666
FF-FRE-000961,ANOOP,Confirmed Franchise,11.6246105,75.629621,Kozhikode,Panchayat,Villiappalli,4,Villiappalli,673542
FF-FRE-000980,Biju Varghese,Confirmed Franchise,9.327184743,76.6655145,Pathanamthitta,Panchayat,Aranmula,3,Edayaranmula p. o,689532
FF-FRE-000983,Binesh mundokkil,Confirmed Franchise,10.73711799,76.0944487,Palakkad,Panchayat,Chalissery,9,Chalissery,679536
FF-FRE-000991,Chithra babu,Confirmed Franchise,9.276427499,76.45119082,Alappuzha,Muncipapality,Haripad,23,Haripad,690514
FF-FRE-000995,DILEEP S,Confirmed Franchise,9.004397562,76.78330538,Kollam,Panchayat,Kulakkada,11,Perumkulam,691566
FF-FRE-000996,DINESH . N,Confirmed Franchise,10.926025,75.893102,Malappuram,Panchayat,Niramaruthoor,3,Niramaruthoor,676109
FF-FRE-001000,Edwin Vijay A J,Confirmed Franchise,11.2634528,75.7993669,Kozhikode,Corporation,Kozhikode,26,Kottooli,673016
FF-FRE-001003,FASAL K A,Confirmed Franchise,9.8477205,76.3351425,Alappuzha,Panchayat,PANAVALLY,3,THRICHATTUKULAM,688526
FF-FRE-001009,GEORGE K JOHN(REJY),Confirmed Franchise,10.54977205,76.31945083,Thrissur,Panchayat,PANANCHERY,22,PATTIKKAD,680652
FF-FRE-001010,Gopinath Nambayil,Confirmed Franchise,11.17228805,75.82286543,Kozhikode,Muncipapality,Feroke,38,Karuvanthiruthy,673631
FF-FRE-001011,Habeeb P A,Confirmed Franchise,10.002817,76.2858945,Ernakulam,Corporation,Cochin Corporation,69,Pachalam,682012
FF-FRE-001028,JOJO. P. T,Confirmed Franchise,10.18921461,76.22431967,Ernakulam,Panchayat,Chendamanglam,4,Gothuruth,683516
FF-FRE-001035,Kiran jose,Confirmed Franchise,9.668897593,76.7259602,Kottayam,Panchayat,Menachil,6,Poovarany,686577
FF-FRE-001037,Kuriyakose Baby,Confirmed Franchise,10.2128201,76.3801549,Ernakulam,Panchayat,Parkkadavu,15,Elavoor,683572
FF-FRE-001050,Modi C M,Confirmed Franchise,10.1237929,76.34670745,Ernakulam,Muncipapality,Aluva,3,Thottakkattukara,683108
FF-FRE-001054,Muhammed Rashid,Confirmed Franchise,11.3346371,75.7727316,Kozhikode,Panchayat,Kakkodi,1,Makkada,673611
FF-FRE-001057,muhammedsabir,Confirmed Franchise,12.09076303,75.19123526,Kannur,Muncipapality,Payyanur Muncipality,31,Payyanur,670307
FF-FRE-001061,N Pramod,Confirmed Franchise,10.22764332,76.3288206,Thrissur,Panchayat,Annamanada,11,Annamanada,680741
FF-FRE-001066,NISHADEVI K VIJEESH,Confirmed Franchise,10.04403652,76.31403724,Ernakulam,Muncipapality,KALAMASSERY,"37, 24",CHANGAPUZHA NAGAR,"682033, 682022"
FF-FRE-001071,Philip Kurien T,Confirmed Franchise,9.348963611,76.69934131,Pathanamthitta,Panchayat,Thottapuzhazerrey,7,"Chirayirambu, Maramon",689549
FF-FRE-001092,Rajkrishnan. R,Confirmed Franchise,8.464047809,76.98926004,Thiruvananthapuram,Corporation,Thiruvananthapuram,56,Nemom,695020
FF-FRE-001104,Saiji K P,Confirmed Franchise,10.19083893,76.38934137,Ernakulam,Muncipapality,Angamali,14,Nayathode,683572
FF-FRE-001106,Sajish Mohan K,Confirmed Franchise,11.269188,75.8238129,Kozhikode,Corporation,Kozhikode Corporation,21,Chevayur,673017
FF-FRE-001114,Sarji T S,Confirmed Franchise,10.27038876,76.3561055,Thrissur,Panchayat,KORATTY,19,KINFRA PARK KORATTY,680308
FF-FRE-001118,Shainal John,Confirmed Franchise,9.9810375,76.4035156,Ernakulam,Panchayat,PUTHENCRUZ,13,Ambalamedu,682303
FF-FRE-001125,Sheela Binu,Confirmed Franchise,9.8676313,76.3238568,Alappuzha,Panchayat,Arookutty,13,Arookutty,688535
FF-FRE-001129,Shiju Solomon,Confirmed Franchise,10.01552637,76.52433152,Ernakulam,Panchayat,Mazhuvanoor,7,Nellad,686669
FF-FRE-001133,Siraj CA,Confirmed Franchise,11.60233018,75.59076919,Kozhikode,Muncipapality,Vadakara,11,Nut street,673104
FF-FRE-001136,Sreeja Honey,Confirmed Franchise,8.5035268,77.0015853,Thiruvananthapuram,Corporation,Valiyavila,41,Thirumala,695006
FF-FRE-001138,Sreejith,Confirmed Franchise,9.570121024,76.32040836,Alappuzha,Panchayat,Mararikulam South,6,Kalavoor,688522
FF-FRE-001145,Subash,Confirmed Franchise,8.490160928,77.0385091,Thiruvananthapuram,Panchayat,Panchayat,XVl 218,Machel,695571
FF-FRE-001148,SUGESH VALSAN,Confirmed Franchise,11.86873305,75.40296786,Kannur,Corporation,CHELORA CORPORATION,30,ATTADAPPA,670018
FF-FRE-001155,SYAM.N,Confirmed Franchise,11.2935829,75.81707814,Kozhikode,Corporation,KOZHIKODE,12,MALAPPARAMP,673009
FF-FRE-001156,T.P.Rupesh,Confirmed Franchise,10.1785159,76.2245168,Ernakulam,Panchayat,Chendamangalam,14,Vadakkumpuram.P.O,683521
FF-FRE-001162,Ummer CH,Confirmed Franchise,11.02224942,76.11798473,Malappuram,Panchayat,Koottilangadi,15,Koottilangadi,676506
FF-FRE-001165,Venugopal M J,Confirmed Franchise,8.557985,76.9398774,Thiruvananthapuram,Corporation,Thiruvananthapuram,13,NALANCHIRA,695015
FF-FRE-001170,VINOD KUMAR M G,Confirmed Franchise,8.501211932,77.01211659,Thiruvananthapuram,Panchayat,VILAVOORKAL,2,PERUKAVU,695573
FF-FRE-001599,Abdul Rafeeq P,Confirmed Franchise,9.853511218,76.40040162,Ernakulam,Panchayat,Amballur,12,Kulayettikara P.O.,682317
FF-FRE-001602,Abhilash Kp,Confirmed Franchise,9.691046959,76.47521448,Kottayam,Panchayat,Kallara,6,Kallara,686611
FF-FRE-001605,Ajayakumar.v,Confirmed Franchise,8.4646803,76.9541149,Thiruvananthapuram,Corporation,Thiruvananthapuram,69,Manacaud,695009
FF-FRE-001607,AJESH VIJAYAN K,Confirmed Franchise,10.6221623,76.03202595,Thrissur,Muncipapality,GURUVAYUR,34,KOTTAPADY,680505
FF-FRE-001610,Al AMEEN,Confirmed Franchise,8.5808359,76.8985398,Thiruvananthapuram,Corporation,THIRUVANANTHAPURAM,3,"THUNDATHIL, KARIYAVATTOM",695581
FF-FRE-001613,Aneesh M.R,Confirmed Franchise,10.93166446,76.47304263,Palakkad,Panchayat,Krimbuzha,9,Elambulassry,678595
FF-FRE-001614,Anish R,Confirmed Franchise,10.83990351,76.11199167,Palakkad,Panchayat,Paradur,2,Pallipuram,679305
FF-FRE-001616,ANVAR. A,Confirmed Franchise,8.90418497,76.5671035,Kollam,Corporation,Kollam Corporation,6,Kavanadu,691003
FF-FRE-001617,Anwar Sadath,Confirmed Franchise,9.08876685,76.56826843,Kollam,Panchayat,Thazhava,13,S.R.P.M.,690539
FF-FRE-001626,Bibin Das Y,Confirmed Franchise,8.441111,76.945316,Thiruvananthapuram,Corporation,Thiruvananthapuram,66,Poonthura,695026
FF-FRE-001636,Dinesh J Devadas (Manoj),Confirmed Franchise,9.432426343,76.90048906,Kottayam,Panchayat,Erumely,15,Edakadathy,686510
FF-FRE-001641,George Abraham,Confirmed Franchise,9.951389383,76.3010781,Ernakulam,Corporation,Kochi,60,Kadavanthra,682020
FF-FRE-001645,Jiji Jerry,Confirmed Franchise,8.8520486,76.6468984,Kollam,Panchayat,Mayyanad,19,Pullichira,691304
FF-FRE-001649,Jojixavier,Confirmed Franchise,9.442945093,76.4430206,Alappuzha,Panchayat,Champakkulam,3,Punnakunnam,688504
FF-FRE-001661,Mathew P M,Confirmed Franchise,9.5977139,76.659557,Kottayam,Panchayat,Kooroppada,6,Kooroppada,686502
FF-FRE-001662,Mithun t,Confirmed Franchise,10.96122706,75.94494442,Malappuram,Panchayat,Ponmundam,16,Ponmundam,676106
FF-FRE-001667,Nassar. A. M,Confirmed Franchise,10.1534634,76.3298987,Ernakulam,Panchayat,Kunnukara,6,S. Aduvassery,683578
FF-FRE-001670,P.pramoth kumar,Confirmed Franchise,11.32017788,75.82487238,Kozhikode,Panchayat,Kakkodi panchayath,13,Kizhakkumurri,673611
FF-FRE-001671,Pradeep MV,Confirmed Franchise,8.505460547,76.98513623,Thiruvananthapuram,Corporation,THIRUVANANTHAPURAM,36,Thirumala,695006
FF-FRE-001672,PRAGIL M M,Confirmed Franchise,11.24895796,75.81257343,Kozhikode,Corporation,KOZHIKODE,30,KOMMERI,673007
FF-FRE-001673,PRAMOD K K,Confirmed Franchise,10.87621793,76.42431014,Palakkad,Panchayat,Sreekrishnapuram,8,Punchappadam,678633
FF-FRE-001678,Radhakrishnan.v,Confirmed Franchise,10.62345163,76.59303688,Palakkad,Panchayat,Nemmera,"1,2",Cheramanglam,678703
FF-FRE-001679,Raihanath. AT,Confirmed Franchise,10.875196,76.1227129,Palakkad,Panchayat,Thiruvegappura,6,Kaipuram,679308
FF-FRE-001680,Rajendra Kumar PP,Confirmed Franchise,11.08364634,76.17313876,Malappuram,Panchayat,Anakkayam,12,Kadambode,676521
FF-FRE-001685,Rajesh kumar k s,Confirmed Franchise,8.980651654,76.72009275,Kollam,Panchayat,Ezhukone,10,Thrippilazhikom,691509
FF-FRE-001689,Ramesh kumar,Confirmed Franchise,8.609765532,77.03310175,Thiruvananthapuram,Panchayat,Uzhamalackal,15,Paruthikuzhy p o,695541
FF-FRE-001690,Ranjithkumar k,Confirmed Franchise,11.336054,75.774304,Kozhikode,Panchayat,Kakkodi,1,Makkada,673611
FF-FRE-001697,Rishan,Confirmed Franchise,8.879336674,76.6197755,Kollam,Corporation,Kollam Corporation,28,Vadakkevila,691010
FF-FRE-001699,Sajith Nanadath,Confirmed Franchise,11.28159914,75.7781485,Kozhikode,Corporation,Kozhikode,68,Nadakkavu,673011
FF-FRE-001707,Sarath C S,Confirmed Franchise,10.34347971,76.2004844,Thrissur,Muncipapality,IRINJALAKUDA,2,IRINJALAKUDA,680121
FF-FRE-001708,Sarin C U,Confirmed Franchise,9.900539179,76.70082551,Idukki,Muncipapality,Thodupuzha,34,Manakkad,685608
FF-FRE-001711,Shabeer,Confirmed Franchise,8.59222503,77.05444586,Thiruvananthapuram,Panchayat,,,,
FF-FRE-001713,Shereena,Confirmed Franchise,8.821028133,76.97520277,Kollam,Panchayat,Chitharapanchayath,2,Valavupacha post,691559
FF-FRE-001718,Shylesh sisupalan,Confirmed Franchise,8.894523693,76.59652275,Kollam,Corporation,Kollam corparation,15,Asramam po,691002
FF-FRE-001721,Sreekutty TS,Confirmed Franchise,10.59864579,76.2270785,Thrissur,Panchayat,Mulamkunnathukavu,4,Mulamkunnathukavu,680581
FF-FRE-001725,SUNIL KUMAR V,Confirmed Franchise,8.734305845,76.89481865,Thiruvananthapuram,Panchayat,PULIMATH,10,PULIMATH,695612
FF-FRE-001730,Vinod p r,Confirmed Franchise,9.9818753,76.4678881,Ernakulam,Panchayat,Peringottukurissi,8,Paruthipully,678573
FF-FRE-002170,Ajison Cyriac,Confirmed Franchise,9.405776,76.795563,Pathanamthitta,Panchayat,Vellavoor,6,Manimala,686543
FF-FRE-002171,ALIMON,Confirmed Franchise,10.80192845,76.12585503,Palakkad,Panchayat,KAPPUR,6,KAPPUR,679552
FF-FRE-002173,Anilkumar,Confirmed Franchise,8.911957036,76.54764782,Kollam,Corporation,Kollam,54,Maruthady,691003
FF-FRE-002174,ANILKUMAR R,Confirmed Franchise,8.894978169,76.61422161,Kollam,Corporation,Kollam,15,Kilikolloor,691004
FF-FRE-002177,Anurajesh,Confirmed Franchise,12.096752,75.2940971,Kannur,Panchayat,Kadannappalli pannapuzha,10,Kadannappalli,670504
FF-FRE-002183,BAIJU C,Confirmed Franchise,11.1429854,75.8296564,Kozhikode,Panchayat,Kadalundi,14,Kadalundi,673302
FF-FRE-002184,Biju Antony,Confirmed Franchise,9.569904455,76.81735893,Kottayam,Panchayat,Parathodu,3,Vengathanam,686512
FF-FRE-002187,Biju R Nair,Confirmed Franchise,10.11539824,76.47505224,Ernakulam,Muncipapality,Perumbavoor,21,Perumbavoor,683542
FF-FRE-002188,Susan ( Binoy Abraham ),Confirmed Franchise,9.577053007,76.5970687,Kottayam,Panchayat,Pampady,20,Vellore,686501
FF-FRE-002189,Binu chacko,Confirmed Franchise,9.407256095,76.55918321,Pathanamthitta,Panchayat,Peringara,7,Alumthuruthy P. O.,689113
FF-FRE-002194,Geethamol k s,Confirmed Franchise,11.06178846,76.55741948,Palakkad,Panchayat,Agali pachayath,1,Mukkali,678582
FF-FRE-002198,Harikumar vk,Confirmed Franchise,8.718225531,76.90099207,Thiruvananthapuram,Panchayat,Vamanapuram,4,Vamanapuram,695606
FF-FRE-002217,KITTUMANI M,Confirmed Franchise,10.63596644,76.55111367,Palakkad,Panchayat,ALATHUR,8,PERINKULAM,678542
FF-FRE-002219,Krishna kumar,Confirmed Franchise,10.21286185,76.26517637,Thrissur,Panchayat,Poyya panchayath,6,Pooppathy,680733
FF-FRE-002222,Luke P Jacob,Confirmed Franchise,8.950869986,76.62089811,Kollam,Panchayat,Panayam,4,Panayam,691601
FF-FRE-002227,Manu mg,Confirmed Franchise,9.367657,76.496015,Alappuzha,Panchayat,Panchayat,4,Thalavady,689572
FF-FRE-002229,Midhun v s,Confirmed Franchise,10.15383565,76.33927956,Ernakulam,Panchayat,Chengamanad,2,Chengamanad,683578
FF-FRE-002230,MIYANDAD TP,Confirmed Franchise,11.22067429,76.14191171,Malappuram,Panchayat,Edavanna,5,Edavanna,676541
FF-FRE-002233,NASSER HUSSAIN,Confirmed Franchise,10.62593866,76.45084794,Palakkad,Panchayat,KANNAMBRA,2,KANNAMBRA,678686
FF-FRE-002240,PRASHOB KIRAN,Confirmed Franchise,11.28681954,75.77413608,Kozhikode,Corporation,KOZHIKODE,70,WESTHILL,673005
FF-FRE-002242,Priya Jiji,Confirmed Franchise,10.05435154,76.43113423,Ernakulam,Panchayat,Kizhakkambalam,11,Vilangu P O,683561
FF-FRE-002246,Rahim M M,Confirmed Franchise,10.22695153,76.20295277,Thrissur,Muncipapality,Kodungallur,9,Pullut,680663
FF-FRE-002248,Rajani Mathew,Confirmed Franchise,9.9274147,76.2679052,Ernakulam,Corporation,Kochi,12,Palluruthy,682006
FF-FRE-002253,Raveendran M. V,Confirmed Franchise,12.11512559,75.21156424,Kannur,Muncipapality,Muni,35,Payyanur 670307,670307
FF-FRE-002254,Readhan N Roopan,Confirmed Franchise,8.477750877,76.98088932,Thiruvananthapuram,Corporation,Trivandrum 16,17,Poojappura,695012
FF-FRE-002258,Roji V George,Confirmed Franchise,9.73355986,76.6602555,Kottayam,Panchayat,Karoor,9,Edanadu,686574
FF-FRE-002263,Santhosh kesavan p p,Confirmed Franchise,12.01819501,75.32645182,Kannur,Panchayat,Pattuvam Panchayat,9,Pattuvam,670143
FF-FRE-002266,Shahanavas Puthiyottil,Confirmed Franchise,11.53432036,75.77310781,Kozhikode,Panchayat,Kayanna,1,Kayanna Bazar,673525
FF-FRE-002272,Shiju Michael,Confirmed Franchise,11.03471912,76.42239121,Palakkad,Panchayat,Kottoppadam,3,Kandamangalam,678583
FF-FRE-002273,Shyth m,Confirmed Franchise,11.57662868,75.6098141,Kozhikode,Muncipapality,Vadakara,32,Puduppanam,673105
FF-FRE-002276,Siyad AK,Confirmed Franchise,9.6865137,76.78245987,Kottayam,Muncipapality,Erattupetta,7ward,Nadackal,686121
FF-FRE-002278,SOJAN GEORGE VARUGHESE,Confirmed Franchise,9.346550623,76.68640345,Pathanamthitta,Panchayat,Thottapuzhasaeri,10,Maramon,688549
FF-FRE-002281,SUDHAN N(SUNIL KUMAR S),Confirmed Franchise,9.525989323,76.33184295,Alappuzha,Panchayat,ARYAD,14,AVALOOKUNNU,688006
FF-FRE-002288,Vinayakumar,Confirmed Franchise,8.39249694,76.9862874,Thiruvananthapuram,Panchayat,venganoor,XVIII,Vizhinjam,695521
FF-FRE-002758,ANEESH UP,Confirmed Franchise,12.0048512,75.44722171,Kannur,Panchayat,MAYYIL,3,MAYYIL,670602
FF-FRE-002759,Antony Augustine,Confirmed Franchise,8.918702344,76.54264938,Kollam,Corporation,Kollam,3,Sakthikulangara,691581
FF-FRE-002761,Arun Gopinath,Confirmed Franchise,10.0069577,76.48681207,Ernakulam,Panchayat,Mazhuvannur,13,Mazhuvannur,686669
FF-FRE-002765,BAIJU MS,Confirmed Franchise,8.819999546,76.75200814,Kollam,Panchayat,panchayat,20,KALLUVATHUKKAL,691578
FF-FRE-002767,Biju Joseph,Confirmed Franchise,10.7372454,76.3912778,Thrissur,Panchayat,Kondazhy,4,Kondazhy,679106
FF-FRE-002769,Binu,Confirmed Franchise,8.678857286,76.79538038,Thiruvananthapuram,Panchayat,Kadakkavoor,5,Keezhattingal,695306
FF-FRE-002770,BrijithLal T,Confirmed Franchise,10.52023422,76.19703262,Thrissur,Corporation,Thrissur,50/323,Ayyanthole,680003
FF-FRE-002772,C K SATHISH KUMAR,Confirmed Franchise,10.64161833,76.54938591,Palakkad,Panchayat,ALATHUR,6,ALATHUR,678541
FF-FRE-002779,Hassan Basari,Confirmed Franchise,11.3108353,75.8243785,Kozhikode,Panchayat,Kuruvattur,14,Parambil,673012
FF-FRE-002788,KIRANKUMAR S,Confirmed Franchise,9.6981573,76.4260635,Kottayam,Panchayat,Thalayazham,10,Thalayazham,686607
FF-FRE-002789,L Ushakumari,Confirmed Franchise,8.403396984,77.08329529,Thiruvananthapuram,Muncipapality,Neyyattinkara,40,Neyyattinkara,695121
FF-FRE-002790,Latheef kuruvampuram,Confirmed Franchise,11.100147,75.921017,Malappuram,Panchayat,Peruvallur,11,Peruvallur,673638
FF-FRE-002791,Malleeswaran. R,Confirmed Franchise,11.13420115,76.68219577,Palakkad,Panchayat,Agali grama panchayath,7,Kottathara 678581,678581
FF-FRE-002794,Mini,Confirmed Franchise,11.30575919,75.79917969,Kozhikode,Corporation,Kozhikode,10,Vengeri,673010
FF-FRE-002796,Mohammed Safwan m. K,Confirmed Franchise,11.87063763,75.38251749,Kannur,Corporation,Kannur,XLIX,Thana,670012
FF-FRE-002798,MV Joy,Confirmed Franchise,10.27438323,76.32137431,Thrissur,Panchayat,Kadukutty panchayath,9,Kathikudam,680308
FF-FRE-002801,P M SUNILKUMAR,Confirmed Franchise,10.57263139,76.12918491,Thrissur,Panchayat,Tholour Panchayat,5,Tholour,680552
FF-FRE-002804,Radhakumari,Confirmed Franchise,8.593034888,76.97186936,Thiruvananthapuram,Panchayat,Karakulam,5,Venkode,695028
FF-FRE-002805,Rajan Madhavan,Confirmed Franchise,9.118055,76.561229,Alappuzha,Panchayat,Vallikunnam,14,Vallikunnam,690501
FF-FRE-002806,Rajesh Kumar S,Confirmed Franchise,9.305503438,76.72956115,Pathanamthitta,Panchayat,Elanthoor,1,Nelikkala,689643
FF-FRE-002809,Ranjit.G.S,Confirmed Franchise,8.870023435,76.61610515,Kollam,Corporation,Kollam,40,Thekkevila,691016
FF-FRE-002812,Reshma M S,Confirmed Franchise,10.04102389,76.3603265,Ernakulam,Muncipapality,Kalamassery,17,Vadacode P O,682021
FF-FRE-002817,Santhosh. Cu,Confirmed Franchise,12.05984648,75.45650068,Kannur,Panchayat,Chengalayi,17,Chuzhali,670142
FF-FRE-002820,Shaji poulose k j,Confirmed Franchise,8.519010357,76.96042929,Thiruvananthapuram,Corporation,Trivandrum,23,KOWDIAR,695003
FF-FRE-002823,Shibu Thomas,Confirmed Franchise,9.396963076,76.63262364,Pathanamthitta,Panchayat,Kallooppara,11,Kallooppara,689583
FF-FRE-002825,Shijohn J Tharayil,Confirmed Franchise,10.51743099,76.18613866,Thrissur,Corporation,Thrissur,54,Pullazhi,680012
FF-FRE-002826,SIBI V ANTONY,Confirmed Franchise,10.54965327,76.14850386,Thrissur,Panchayat,ADAT,16,ADAT,680551
FF-FRE-002828,SITHESH MK,Confirmed Franchise,11.27548596,75.81332122,Kozhikode,Corporation,Kozhikode,24,CHEVARAMBALAM,673017
FF-FRE-002829,Sreejith,Confirmed Franchise,10.87007351,75.9628036,Malappuram,Panchayat,Thirunavaya,13,Kodakkal,676108
FF-FRE-002835,Sumon k s,Confirmed Franchise,9.547943173,76.58146031,Kottayam,Panchayat,പുതുപ്പള്ളി,9,പുതുപ്പള്ളി,686011
FF-FRE-002836,Sunil Au,Confirmed Franchise,10.10413468,76.35557119,Ernakulam,Muncipapality,Aluva,17,Aluva post office,683101
FF-FRE-002837,Sunil N N,Confirmed Franchise,9.949115395,76.42638601,Ernakulam,Panchayat,PUTHENCRUZ,7,Thiruvaniyoor,682308
FF-FRE-002839,Sunitha Mol,Confirmed Franchise,9.3214454,76.4482053,Alappuzha,Panchayat,Cheruthana,3,Ayaparampu,690517
FF-FRE-002841,Thomas Kurien,Confirmed Franchise,9.462981325,76.56249064,Kottayam,Panchayat,Vazhappally,13,I E Nagar,686106
FF-FRE-002849,Ranju P Raghavan,Confirmed Franchise,10.0069577,76.48681207,Ernakulam,Panchayat,Mazhuvannoor,16,South mazhuvannoor,686669
FF-FRE-003014,Jijeesh K V,Confirmed Franchise,12.2090938,75.2632654,Kannur,Panchayat,Kankol alapadamba,3,Aravanchal,670353
FF-FRE-003175,BIJU T K,Confirmed Franchise,10.50615962,76.2764838,Thrissur,Panchayat,Nadathara,11,Kozhukkully,680751
FF-FRE-003182,Naveen PJ,Confirmed Franchise,8.473340147,76.95501627,Thiruvananthapuram,Corporation,Thiruvananthapuram,71,Manacaud,695009
FF-FRE-003183,Resmi. R,Confirmed Franchise,8.568153002,76.86733124,Thiruvananthapuram,Corporation,Kazhakuttom,1,Kazhakuttom,695582
FF-FRE-003188,സജിത്ത്,Confirmed Franchise,8.683907349,76.77578822,Thiruvananthapuram,Panchayat,ചിറയിൻകീഴ്,1,കടയ്ക്കാവൂർ,695306
FF-FRE-003192,VeenaSoonu,Confirmed Franchise,8.684884945,76.74743764,Thiruvananthapuram,Panchayat,Anchuthengu,Kayikkara,Kayikkara.P.O,69530
FF-FRE-003198,SereenaNoushad,Confirmed Franchise,8.930588568,76.63976948,Kollam,Panchayat,Perinad,15,Chanthanathoppe,691014
FF-FRE-003201,Binu. S,Confirmed Franchise,8.8947093,76.6034992,Kollam,Corporation,Kollam,17,Kadappakada,691008
FF-FRE-003203,Ambili K,Confirmed Franchise,8.669030682,76.79906782,Thiruvananthapuram,Panchayat,Chirayinkeezhu,8,Chirayinkeezhu,685304
FF-FRE-003206,Joy cheriyan,Confirmed Franchise,10.00709514,76.68070551,Ernakulam,Panchayat,Pothanicad,3,Pothanicad,686671
FF-FRE-003210,Abdul Rahiman Muhammed,Confirmed Franchise,10.74408714,76.17749925,Palakkad,Panchayat,Abdul Rahiman Muhammed,9,Peringannur,679535
FF-FRE-003213,Binu Baby,Confirmed Franchise,9.062626305,76.84920411,Kollam,Panchayat,Pattazhy,4,Pattazhy,691522
FF-FRE-003216,Aghilesh Kanangot,Confirmed Franchise,11.22317406,75.79160973,Kozhikode,Corporation,Kozhikode,55,Kallai,673003
FF-FRE-003218,Ralith Ram,Confirmed Franchise,10.57089069,76.21668549,Thrissur,Panchayat,Kolazhy,10,Kolazhy,680010
FF-FRE-003224,Dhanesh mk,Confirmed Franchise,9.971032438,76.42553536,Ernakulam,Panchayat,PUTHENCRUZ,8,B k puram,682308
FF-FRE-003238,TOJY K JAMES,Confirmed Franchise,9.472120008,76.5606547,Kottayam,Panchayat,Vazhappally,11,Cheeranchira P. O,686106
FF-FRE-003483,BALAKRISHNAN T. K,Confirmed Franchise,11.62153468,75.58630572,Kozhikode,Panchayat,Chorode Panchayath,13,Chorode,673106
FF-FRE-003488,VIJEESH,Confirmed Franchise,10.79420542,75.95862803,Malappuram,Muncipapality,Ponnani,13,Biyyam,679577
FF-FRE-003489,Binu George,Confirmed Franchise,10.42494868,76.27453785,Thrissur,Panchayat,Pudukad Grama Panchayath,3,Pudukad,680301
FF-FRE-003493,JAYARAJ S,Confirmed Franchise,9.792699857,76.35009832,Alappuzha,Panchayat,THYCATTUSSERY,3,POOCHAKKAL,688526
FF-FRE-003500,Rajesh M,Confirmed Franchise,10.61371015,76.69664273,Palakkad,Panchayat,Peruvemba,2,Thannisseri,678501
FF-FRE-003571,AJITH REVIKUMAR,Confirmed Franchise,9.754305488,76.66094874,Kottayam,Panchayat,Karoor,II,Nechipuzhoor,686574
FF-FRE-003573,Anila T Raj,Confirmed Franchise,8.46239,77.161505,Thiruvananthapuram,Panchayat,Vellarada,21,Mannamkonam,695125
FF-FRE-003574,Devi Priya U,Confirmed Franchise,9.922911,76.271135,Ernakulam,Corporation,Kochi corporation,21,Palluruthy,682006
FF-FRE-003584,Simpson,Confirmed Franchise,8.485101236,76.90908764,Thiruvananthapuram,Corporation,Shanghumughom,89,Shanghumugom,695007
FF-FRE-003630,Babu rajan,Confirmed Franchise,10.9005525,76.2590881,Malappuram,Panchayat,Elamkulam,7,Elad,679340
FF-FRE-003635,Roy John,Confirmed Franchise,9.628684931,76.31219896,Alappuzha,Muncipapality,cherthala,25,cherthala,688524
FF-FRE-003688,Padmakumar.G,Confirmed Franchise,8.7044897,76.8086845,Thiruvananthapuram,Muncipapality,Attingal,29,Attingal,695101
FF-FRE-003702,Manojkumar,Confirmed Franchise,10.56348531,76.15455193,Thrissur,Panchayat,Avanur,8,Choolissery,680541
FF-FRE-003733,Santhosh M,Confirmed Franchise,10.994701,76.2073671,Malappuram,Panchayat,Angadipuram,6,Valambur,679325
FF-FRE-003736,Shiyas KM,Confirmed Franchise,8.549650507,77.09065613,Thiruvananthapuram,Panchayat,Poovachal,3,Poovachal,695575
FF-FRE-003791,Divya sunil,Confirmed Franchise,10.096615,76.3494729,Ernakulam,Panchayat,Choornikara,2,Aluva,683101
FF-FRE-003817,Sherin Surendran,Confirmed Franchise,9.147430429,76.48679307,Alappuzha,Panchayat,Devikulangara,12,Devikulangara,690527
FF-FRE-003843,Visak,Confirmed Franchise,8.550878216,76.87801145,Thiruvananthapuram,Corporation,Thiruvananthapuram,1,Karyavattom,695581
FF-FRE-003844,Pradeep. K. B,Confirmed Franchise,8.457459329,76.99455277,Thiruvananthapuram,Corporation,Thiruvananthapuram corporation,50,Nemom,695020
FF-FRE-003847,Biju lal. M. O,Confirmed Franchise,8.51133752,76.97446013,Thiruvananthapuram,Corporation,Thiruvanathapuram,22,Sasthamangalam,695010
FF-FRE-003849,Jaison chittilappilly ouseph,Confirmed Franchise,10.54241695,76.15014959,Thrissur,Panchayat,Adat,16,Adat,680551
FF-FRE-003851,SHIBU N V,Confirmed Franchise,10.14601334,76.50011925,Ernakulam,Panchayat,Mudakuzha,13,ELAMBAKAPPILLY,683544
FF-FRE-003852,Abdul Salam,Confirmed Franchise,11.10354637,76.65688316,Palakkad,Panchayat,Agali,9,Agali,678581
FF-FRE-003882,Subeesh Babu Edakkoth,Confirmed Franchise,11.21157537,75.81939645,Kozhikode,Corporation,Kozhikode,42,Nallalam,673027
FF-FRE-003967,Sreejith S,Confirmed Franchise,9.0129483,76.7010615,Kollam,Panchayat,Pavithreswaram,9,Karuvelil,691505
FF-FRE-003974,Madhu,Confirmed Franchise,9.432315708,76.54189297,Kottayam,Panchayat,Chegalam,4,Chegalam south,686022
FF-FRE-003999,Ramadasan,Confirmed Franchise,10.7871213,76.0848679,Palakkad,Panchayat,Pattithara,14,Thalakkassery,679534
FF-FRE-004011,Arun bhaskar,Confirmed Franchise,10.492366,76.341847,Thrissur,Panchayat,Puthur Gramapanchayath,7,Mannamangalam,680014
FF-FRE-004026,SivanandanSaratchandran,Confirmed Franchise,8.694626368,76.73994988,Thiruvananthapuram,Panchayat,Anchuthengu panchayath,2,Nedunganda po,695307
FF-FRE-004034,BENOY JOSEPH,Confirmed Franchise,9.842618834,76.56424878,Ernakulam,Panchayat,ELANJI,3,MUTHOLAPURAM,686665
FF-FRE-004035,Biju P,Confirmed Franchise,10.93697706,76.15398832,Malappuram,Panchayat,Moorkanadu,5,Kolathur,679338
FF-FRE-004091,SURYA W/o Anoop,Confirmed Franchise,10.81568028,76.17796361,Palakkad,Muncipapality,Pattambi,26,Sankaramangalam,679303
FF-FRE-004118,VISWAMBARAN,Confirmed Franchise,10.78065115,76.62886991,Palakkad,Muncipapality,PALAKKAD,49,VADAKKANTHARA,678012
FF-FRE-004132,Alimon k,Confirmed Franchise,10.7340665,76.17160227,Palakkad,Panchayat,Thirumittacode,10,Chathanur,679535
FF-FRE-004147,Deepumon Jk,Confirmed Franchise,8.375571302,77.10799152,Thiruvananthapuram,Panchayat,Deepu Jk,11,Plamootukada,695122
FF-FRE-004158,Rajeev,Confirmed Franchise,11.21995031,75.80669553,Kozhikode,Corporation,Corporation,39,Thiruvannur,673029
FF-FRE-004173,Anish kumar S,Confirmed Franchise,8.968415017,76.92485103,Kollam,Panchayat,Karavaloor,8,Karavaloor,691333
FF-FRE-004193,Franslo Paul,Confirmed Franchise,10.47088588,76.23348784,Thrissur,Panchayat,Paralam,12,Paralam,680563
FF-FRE-004199,MANU DAVID,Confirmed Franchise,10.50021785,76.23765647,Thrissur,Corporation,thrissur,24,kuriachira,680006
FF-FRE-004215,Vijayan Pillai (Sreejith),Confirmed Franchise,10.0069577,76.48681207,Ernakulam,Panchayat,Mazhuvannur,15,Ezhipram,
