import { Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Circle, Fill, Icon, Stroke, Style, Text } from "ol/style";
import { Point } from "ol/geom";

import warehousesData from "../../../map/Warehouse.csv";
import franchiseeData from "../../../Franchisee 437 List.csv";

import districts from "../../../map/district-kerala-area.geojson";
import lsgs from "../../../map/lsgi-kerala-osm.geojson";
import franchiseeBoundaries from "../../../map/franchisee-boundary.geojson";
import { toSize } from "ol/size";

const storeIcon = new URL("store.svg", import.meta.url);

const putFranchiseeInDistrict = (agg, val) => {
  const currDistrict = val["District"];
  if (!(currDistrict in agg)) {
    agg[currDistrict] = [];
  }
  agg[currDistrict].push(val);
  return agg;
};

export const franchiseesByDistrict = franchiseeData.reduce(
  putFranchiseeInDistrict,
  {}
);

export const getFranchiseeDataById = (id) =>
  franchiseeData.filter((f) => f["Franchisee Application No"] === id)[0];

const maximum_franchisees_in_a_district = Math.max(
  ...Object.values(franchiseesByDistrict).map((k) => k.length)
);

const geojsonFormat = new GeoJSON();

const districtFeatures = geojsonFormat.readFeatures(districts);
const districtSource = new VectorSource({
  features: districtFeatures,
  format: geojsonFormat,
});
const styleFunction = (feature, resolution) => {
  const district = feature.get("name");
  const franchiseesHere = franchiseesByDistrict[district] || [];
  const numberOfFranchisees = franchiseesHere.length;
  const ratio = 1 - numberOfFranchisees / maximum_franchisees_in_a_district;
  const greenValue = 182 + 50 * ratio;
  const redValue = 46 + 151 * ratio;
  const blueValue = 44 + 139 * ratio;
  return new Style({
    stroke: new Stroke({
      color: "grey",
      lineDash: [4, 1, 2],
      width: 4,
    }),
    fill: new Fill({
      color: `rgba(${redValue}, ${greenValue}, ${blueValue}, 0.6)`,
    }),
    text: new Text({
      text: district || "UNNAMED",
    }),
  });
};
export const DISTRICT_LAYER = new VectorLayer({
  source: districtSource,
  minZoom: 6,
  title: "Districts",
  style: styleFunction,
});

const lsgFeatures = geojsonFormat.readFeatures(lsgs);
const lsgSource = new VectorSource({
  features: lsgFeatures,
  format: geojsonFormat,
});
export const LSG_LAYER = new VectorLayer({
  source: lsgSource,
  title: "Local Bodies",
  minZoom: 9,
});

const franchiseeFeatures = franchiseeData.map((row) => {
  const geometry = new Point([row["Longitude"], row["Latitude"]]);
  return new Feature({
    geometry,
    ...row,
  });
});
const franchiseeSource = new VectorSource({
  features: franchiseeFeatures,
});
export const FRANCHISEE_LAYER = new VectorLayer({
  title: "Franchisee Location",
  source: franchiseeSource,
  minZoom: 9,
  style: new Style({
    image: new Icon({
      src: storeIcon.toString(),
      scale: 2,
    }),
  }),
});

const warehouseFeatures = warehousesData.map((row) => {
  const geometry = new Point(row["LatLong"].split(",").reverse());
  return new Feature({
    geometry,
    ...row,
  });
});
const warehouseSource = new VectorSource({
  features: warehouseFeatures,
});
export const WAREHOUSE_LAYER = new VectorLayer({
  title: "Warehouse Location",
  source: warehouseSource,
  minZoom: 6,
  maxZoom: 10,
  style: new Style({
    image: new Circle({
      fill: new Fill({ color: "green" }),
      stroke: new Stroke({
        color: "green",
      }),
      radius: 6,
    }),
  }),
});

const franchiseeBoundaryFeatures =
  geojsonFormat.readFeatures(franchiseeBoundaries);
const franchiseeBoundarySource = new VectorSource({
  features: franchiseeBoundaryFeatures,
  format: geojsonFormat,
});
export const FRANCHISEE_BOUNDARY_LAYER = new VectorLayer({
  title: "Franchisee Servicing Area",
  source: franchiseeBoundarySource,
  maxResolution: 0.0003,
  style: new Style({
    stroke: new Stroke({
      color: "green",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(0, 250, 0, 0.6)",
    }),
  }),
});

const putLSGInDistrict = (agg, val) => {
  const currDistrict = val.properties["District"];
  if (!(currDistrict in agg)) {
    agg[currDistrict] = [];
  }
  agg[currDistrict].push(val.properties);
  return agg;
};

export const LSGsByDistrict = lsgs.features.reduce(putLSGInDistrict, {});

export const fieldsToShow = [
  "Franchisee",
  "Franchisee Application No",
  "Name",
  "name",
  "Status",
  "Pin Code",
  "Post Office",
  "Ward Number",
  "District",
  "LSG Name",
  "Warehouse Code",
  "Warehouse Name",
];
