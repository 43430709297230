const API_URL = "http://localhost:3000";

const fetchJson = async (url) => fetch(url).then((res) => res.json());

export const getReality = async (lat, long) =>
  fetchJson(`${API_URL}/reality?lat=${lat}&long=${long}`);

export const getRealityAsText = async (lat, long) =>
  getReality(lat, long).then(data => {
      if (data.length === 0) return "No store delivers here"
      return `Store ${data[0].id} delivers here`
  }).catch(err => ``)