import React, { useEffect, useRef } from "react";

import { Map, View } from "ol";
import Overlay from "ol/Overlay";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import LayerSwitcher from "ol-layerswitcher";

import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "./custom.css";

import {
  DISTRICT_LAYER,
  fieldsToShow,
  FRANCHISEE_BOUNDARY_LAYER,
  FRANCHISEE_LAYER,
  LSG_LAYER,
  WAREHOUSE_LAYER,
  franchiseesByDistrict,
  getFranchiseeDataById,
} from "./data";
import LayerGroup from "ol/layer/Group";
import InfoControl from "./info-control";
import {
  convertObjectToTable,
  fitViewToFeature,
  getFeatureCached,
  getLSGHTMLforDistrict,
  initialInfoBoxBody,
} from "./utils";
import { containsExtent } from "ol/extent";
import { getRealityAsText } from "./api";

export default function FarmFaceViz() {
  const mapRef = useRef(null);

  useEffect(() => {
    const container = document.getElementById("popup");
    const content = document.getElementById("popup-content");
    const closer = document.getElementById("popup-closer");
    const overlay = new Overlay({
      element: container,
    });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    const initView = {
      center: [76.2676, 10.5806],
      zoom: 7,
    };

    const mapView = new View({
      ...initView,
      projection: "CRS:84",
    });

    const infoControl = new InfoControl();

    const map = new Map({
      target: mapRef.current,
      controls: defaultControls().extend([infoControl]),
      interactions: defaultInteractions({ doubleClickZoom: false }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        DISTRICT_LAYER,
        LSG_LAYER,
        new LayerGroup({
          title: "Franchisees",
          layers: [FRANCHISEE_BOUNDARY_LAYER, FRANCHISEE_LAYER],
        }),
        WAREHOUSE_LAYER,
      ],
      view: mapView,
      overlays: [overlay],
    });

    let mapLevel = "state";
    let currentFeature;
    let currentFeatureLevel;

    if (window.location.hostname === "localhost") {
      window["ffMap"] = map;
    }

    const layerSwitcher = new LayerSwitcher({
      reverse: true,
    });

    map.addControl(layerSwitcher);

    const mapViewUp = () => {
      if (mapLevel === "district") {
        mapView.animate(initView);
        infoControl.hideBack();
        currentFeature = undefined;
        currentFeatureLevel = undefined;
        mapLevel = "state";
        infoControl.setContent("Info", initialInfoBoxBody);
      }
      if (mapLevel === "lsg") {
        navigateTo("district", currentFeature.get("District"));
      }
      if (mapLevel === "franchisee") {
        switch (currentFeatureLevel) {
          case "lsg":
            navigateTo("lsg", currentFeature)
            break;
          case "district":
            navigateTo("district", currentFeature.get("name"))
            break;
        }
      }
    };

    infoControl.setContent("Info", initialInfoBoxBody);
    infoControl.backButton.addEventListener("click", (e) => {
      e.stopPropagation();
      mapViewUp();
    });

    const showDistrictInfo = (district) => {
      const franchisees = franchiseesByDistrict[district];
      const info = `Number of Franchisees: ${franchisees?.length || 0}`;
      const lsgs = getLSGHTMLforDistrict(district);
      infoControl.setContent(district, `${info}<br/>${lsgs}`);
      infoControl.showBack();
    };

    const showLSGInfo = (props) => {
      const { geometry, ...goodProps } = props;
      const info = convertObjectToTable(goodProps);
      infoControl.setContent(props.name, info);
      infoControl.showBack();
    };

    const showFranchiseeInfo = (id) => {
      const franchiseeData = getFranchiseeDataById(id);
      const info = convertObjectToTable(franchiseeData);
      infoControl.setContent(franchiseeData["Franchisee Application No"], info);
    };

    const showInfo = (layer, feature) => {
      if (layer === "district") showDistrictInfo(feature.get("name"));
      if (layer === "lsg") showLSGInfo(feature.getProperties());
      if (layer === "franchisee") showFranchiseeInfo(feature.get("Franchisee"));
    };

    const navigateTo = (layer, idOrFeature) => {
      const feature =
        typeof idOrFeature === "string"
          ? getFeatureCached(layer, idOrFeature)
          : idOrFeature;
      fitViewToFeature(mapView, feature);
      mapLevel = layer;
      showInfo(layer, feature);
      if (layer !== "franchisee") {
        currentFeature = feature;
        currentFeatureLevel = layer;
      }
      mapLevel = layer;
      return { layer, feature };
    };

    window["navigateTo"] = navigateTo;

    map.on("singleclick", function (evt) {
      const coordinate = evt.coordinate;
      let districtFeature;
      let lsgFeature;
      let franchiseeFeature;
      map.forEachFeatureAtPixel(
        evt.pixel,
        (feature, layer) => {
          if (layer === DISTRICT_LAYER) {
            districtFeature = feature;
          }
          if (layer === LSG_LAYER) {
            lsgFeature = feature;
          }
          if (layer === FRANCHISEE_BOUNDARY_LAYER) {
            franchiseeFeature = feature;
          }
        },
        {
          layerFilter: (l) =>
            l === DISTRICT_LAYER ||
            l === LSG_LAYER ||
            l === FRANCHISEE_BOUNDARY_LAYER,
        }
      );
      if (!districtFeature && !lsgFeature && !franchiseeFeature) return;
      if (mapLevel === "state" && districtFeature) {
        navigateTo("district", districtFeature.get("name"));
      } else {
        if (franchiseeFeature) {
          navigateTo("franchisee", franchiseeFeature);
        } else if (lsgFeature) {
          navigateTo("lsg", lsgFeature.get("wikidata"));
        } else if (districtFeature) {
          navigateTo("district", districtFeature.get("name"));
        }
      }
    });
    map.on("dblclick", function (evt) {
      const coordinate = evt.coordinate;
      const features = [];
      map.forEachFeatureAtPixel(evt.pixel, (feature) => features.push(feature));
      if (features.length === 0) return;
      const newContent =
        "<table>" +
        features
          .map((f) => f.values_)
          .map((values) =>
            Object.keys(values)
              .filter((k) => fieldsToShow.includes(k))
              .map((k) => `<tr><td>${k}</td><td>${values[k]}</td></tr>`)
              .join("")
          )
          .join("") +
        "</table>";
      content.innerHTML = newContent + "<br/>Querying...";
      overlay.setPosition(coordinate);
      getRealityAsText(coordinate[1], coordinate[0]).then(
        (text) => (content.innerHTML = newContent + text)
      );
    });
  }, []);

  return (
    <div
      ref={mapRef}
      style={{
        height: "100%",
        width: "100%",
        top: 0,
        bottom: 0,
        position: "absolute",
      }}
    >
      <div id="popup" className="ol-popup">
        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
    </div>
  );
}
